import { IconCheck } from '@/components/ui/icons'
import clsx from 'clsx'

export function Steps({ steps, current, onChange }) {
  return (
    <nav
      aria-label="Progress"
      className="flex justify-center px-4 lg:-mt-[56px]"
    >
      <ol
        className="flex w-full flex-wrap items-start justify-start gap-2 divide-y sm:justify-center md:w-10/12 md:divide-y-0"
        role="list"
      >
        {steps.map((step, stepIdx) => {
          const isCompleted = current > stepIdx
          const isCurrent = current === stepIdx
          const isFuture = !isCompleted && !isCurrent
          return (
            // z-50 makes it sit above navbar.tsx for pointer-events to work since the <nav> container is -mt-[56px]
            <li
              className={clsx(
                'relative z-50 rounded-full py-1 pl-3  pr-3 transition-all duration-300 ease-in-out md:flex',
                isCompleted ? 'bg-brand-500/20' : 'bg-brand-500/10',
              )}
              key={`${step.name}-${stepIdx}`}
            >
              <div
                className={clsx(
                  'group flex w-full cursor-pointer items-center focus:outline-none focus-visible:ring-2',
                  (isFuture || isCurrent) && 'pointer-events-none',
                )}
                onClick={() => onChange(stepIdx)}
              >
                <span className="flex items-center gap-2 text-sm font-medium">
                  <span
                    className={clsx(
                      'flex flex-shrink-0 items-center justify-center rounded-full duration-300',
                      isCompleted &&
                        'h-4 w-4 bg-brand-400 text-white  dark:bg-brand-400',
                      isCurrent &&
                        'h-4 w-4 bg-brand-300/80 p-2 dark:bg-vanta-500/50',
                      isFuture &&
                        'h-4 w-4 bg-brand-300/10 p-2 dark:bg-vanta-500/20',
                    )}
                  >
                    {isCompleted ? (
                      <IconCheck className="h-3 w-3 stroke-white stroke-[3] dark:stroke-black" />
                    ) : (
                      <span
                        className={clsx(
                          'text-xs',
                          !isCurrent && 'text-vanta-500',
                        )}
                      >
                        {stepIdx + 1}
                      </span>
                    )}
                  </span>
                  {/* <span
                    className={clsx(
                      'text-sm font-medium duration-300',
                      isCompleted && 'text-brand-400 dark:text-brand-500',
                      isFuture && 'text-vanta-500',
                    )}
                  >
                    {step.name}
                  </span> */}
                </span>
              </div>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}
