'use client'

import { Button } from '@/components/ui/button'
import { MemoizedReactMarkdown } from '@/components/ui/markdown'

import { IconArrowDown } from '@/components/ui/icons'
import clsx from 'clsx'

import {
  motion,
  useMotionTemplate,
  useMotionValue,
  type MotionStyle,
  type MotionValue,
} from 'framer-motion'
import { useTheme } from 'next-themes'
import Image, { type StaticImageData } from 'next/image'
import { useEffect, useState, type MouseEvent } from 'react'
import { useInView } from 'react-intersection-observer'
import { useIsMobile } from '@/lib/hooks/use-is-mobile'
import imageChapter from '@/images/chapter.png'
import imagePrev from '@/images/prev2.png'
import { MockTrackChallenge } from './challenge-card'
import { Steps } from './steps'
import { RadioGroup } from '@/components/ui/radio-group'
import Balancer from 'react-wrap-balancer'
import imageCommunity from '@/images/community.png'

type WrapperStyle = MotionStyle & {
  '--x': MotionValue<string>
  '--y': MotionValue<string>
}

interface CardProps {
  title: string
  description: string
  bgClass?: string
}

function FeatureCard({
  title,
  description,
  bgClass,
  children,
}: CardProps & {
  children: React.ReactNode
}) {
  const [mounted, setMounted] = useState(false)
  const mouseX = useMotionValue(0)
  const mouseY = useMotionValue(0)
  const isMobile = useIsMobile()

  function handleMouseMove({ currentTarget, clientX, clientY }: MouseEvent) {
    if (isMobile) return
    const { left, top } = currentTarget.getBoundingClientRect()
    mouseX.set(clientX - left)
    mouseY.set(clientY - top)
  }

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <motion.div
      className="animated-feature-cards relative w-full rounded-3xl    "
      onMouseMove={handleMouseMove}
      style={
        {
          '--x': useMotionTemplate`${mouseX}px`,
          '--y': useMotionTemplate`${mouseY}px`,
        } as WrapperStyle
      }
    >
      <div
        className={clsx(
          'group relative w-full overflow-hidden rounded-3xl border border-black/20  bg-gradient-to-b from-ash-50/90 to-mirage-100 transition duration-300 dark:from-neutral-950/90 dark:to-neutral-800/90',
          'md:hover:border-transparent',
          bgClass,
        )}
      >
        <div className="mx-10 my-10 min-h-[450px] w-full">
          <div className="flex w-4/6 flex-col gap-3">
            <h2 className="text-xl font-bold tracking-tight md:text-2xl">
              {title}
            </h2>
            <p className="text-sm leading-5 text-vanta-700 dark:text-zinc-400 sm:text-base sm:leading-5">
              <Balancer>{description}</Balancer>
            </p>
          </div>
          {mounted ? children : null}
        </div>
      </div>
    </motion.div>
  )
}

export function ImageCard({
  image,
  imgClass1,
  imgClass2,
  ...props
}: CardProps & {
  imgClass1?: string
  imgClass2?: string
  image: {
    dark1: StaticImageData
    dark2: StaticImageData
    light1: StaticImageData
    light2: StaticImageData
    alt: string
  }
}) {
  const { resolvedTheme } = useTheme()
  return (
    <FeatureCard {...props}>
      <>
        {resolvedTheme === 'light' && (
          <>
            <Image
              alt={image.alt}
              className={imgClass1}
              src={image.light1}
              style={{
                position: 'absolute',
                userSelect: 'none',
                maxWidth: 'unset',
              }}
            />

            <Image
              alt={image.alt}
              className={imgClass2}
              src={image.light2}
              style={{
                position: 'absolute',
                userSelect: 'none',
                maxWidth: 'unset',
              }}
            />
          </>
        )}
        {resolvedTheme === 'dark' && (
          <>
            <Image
              alt={image.alt}
              className={imgClass1}
              src={image.dark1}
              style={{
                position: 'absolute',
                userSelect: 'none',
                maxWidth: 'unset',
              }}
            />
            <Image
              alt={image.alt}
              className={imgClass2}
              src={image.dark2}
              style={{
                position: 'absolute',
                userSelect: 'none',
                maxWidth: 'unset',
              }}
            />
          </>
        )}
      </>
    </FeatureCard>
  )
}

const steps = [
  { id: '1', name: '' },
  { id: '2', name: '' },
  // { id: '3', name: '' },
]

export function ChallengeCreationCard({
  image,
  step1img1Class,
  step1img2Class,
  step2img1Class,
  step2img2Class,
  step3imgClass,
  ...props
}: CardProps & {
  step1img1Class?: string
  step1img2Class?: string
  step2img1Class?: string
  step2img2Class?: string
  step3imgClass?: string
  image: {
    step1dark1: StaticImageData
    step1dark2: StaticImageData
    step1light1: StaticImageData
    step1light2: StaticImageData
    step2dark1: StaticImageData
    step2dark2: StaticImageData
    step2light1: StaticImageData
    step2light2: StaticImageData
    step3dark: StaticImageData
    step3light: StaticImageData
    alt: string
  }
}) {
  const { resolvedTheme } = useTheme()
  const { currentNumber: step, increment } = useNumberCycler()

  return (
    <FeatureCard {...props}>
      <div
        className={clsx(
          { 'translate-x-0 opacity-0': step < 3 },
          'absolute left-2/4 top-1/3 flex w-[100%] -translate-x-1/2 -translate-y-[33%] flex-col gap-12 text-center text-2xl font-bold transition-all duration-500 md:w-[90%]',
        )}
      >
        <Image
          alt={image.alt}
          className="pointer-events-none  top-[50%] w-[90%] overflow-hidden rounded-t-2xl border border-zinc-300 transition-all duration-500 dark:border-zinc-700 md:left-[35px] md:top-[30%] md:w-full"
          src={'/landing/course.png'}
          width={800}
          height={300}
          style={{
            position: 'absolute',
            userSelect: 'none',
            maxWidth: 'unset',
          }}
        />
      </div>

      <>
        {/* step 1 */}
        <Image
          alt={image.alt}
          className={clsx(step1img1Class, {
            '-translate-x-36 opacity-0': step > 0,
          })}
          src={image.step1light1}
          style={{
            position: 'absolute',
            userSelect: 'none',
            maxWidth: 'unset',
          }}
        />
        <Image
          alt={image.alt}
          className={clsx(step1img2Class, {
            '-translate-x-24 opacity-0': step > 0,
          })}
          src={image.step1light2}
          style={{
            position: 'absolute',
            userSelect: 'none',
            maxWidth: 'unset',
          }}
        />

        {/* step 2 */}
        <Image
          alt={image.alt}
          className={clsx(
            step2img1Class,
            { 'translate-x-36 opacity-0': step < 1 },
            { '-translate-x-36 opacity-0': step > 1 },
          )}
          src={image.step2light1}
          style={{
            position: 'absolute',
            userSelect: 'none',
            maxWidth: 'unset',
          }}
        />
        <Image
          alt={image.alt}
          className={clsx(
            step2img2Class,
            { 'translate-x-24 opacity-0': step < 1 },
            { '-translate-x-24 opacity-0': step > 1 },
          )}
          src={image.step2light2}
          style={{
            position: 'absolute',
            userSelect: 'none',
            maxWidth: 'unset',
          }}
        />
        {/* step 3 */}
        <Image
          alt={image.alt}
          className={clsx(
            step3imgClass,
            { 'translate-x-36 opacity-0': step < 2 },
            { '-translate-x-36 opacity-0': step > 2 },
            { 'opacity-90': step === 2 },
          )}
          src={image.step3light}
          style={{
            position: 'absolute',
            userSelect: 'none',
            maxWidth: 'unset',
          }}
        />
        <div className="absolute left-[12rem] top-5 z-50 h-full w-full cursor-pointer md:left-0">
          <Steps current={step} onChange={() => {}} steps={steps} />
        </div>
      </>

      <div
        className="absolute right-0 top-0 z-50 h-full w-full cursor-pointer md:left-0"
        onClick={() => increment()}
      />
    </FeatureCard>
  )
}

function Badge({ name }: { name: string }) {
  return (
    <Button
      tabIndex={-1}
      className="-ml-[0.33rem] flex h-auto w-fit items-center rounded-full bg-transparent py-1 pl-[0.33rem] pr-2 text-xs font-bold text-neutral-700 hover:bg-black/10 dark:text-white dark:hover:bg-white/20"
      size="sm"
    >
      @{name}
    </Button>
  )
}

function Features2() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-2">
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
          <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-brand-500">
                Section
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                From idk to <span className="font-brand">gnow</span>
              </p>

              <p className="mt-6 text-lg leading-8 text-gray-600">
                <Balancer>
                  Sections are effortlessly customizable, enabling you to
                  integrate the AI tools you need, precisely at the moments you
                  need them.
                </Balancer>
              </p>
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <div className="relative isolate overflow-hidden bg-brand-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-4xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
              <div
                className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-brand-100 opacity-20 ring-1 ring-inset ring-white"
                aria-hidden="true"
              />
              <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                <Image
                  src={imageChapter}
                  alt="Product screenshot"
                  width={2432}
                  height={1442}
                  className="-mb-12 w-[59rem] max-w-none rounded-tl-4xl bg-gray-800 ring-1 ring-white/10"
                />
              </div>
              <div
                className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export function CollaborativeEnvironmentCard(props: CardProps) {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  return (
    <FeatureCard {...props}>
      <div
        ref={ref}
        className="absolute inset-0 left-[23px] top-[30%] flex w-[100%] flex-col gap-3 pt-4 max-md:scale-90 sm:top-[35%] md:left-[37px] md:top-[15%]"
      >
        <div className="relative isolate overflow-hidden rounded-3xl  px-6 pt-2 sm:mx-auto sm:max-w-2xl sm:rounded-4xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
          <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
            <Image
              src={imageCommunity}
              alt="Product screenshot"
              width={2432}
              height={1442}
              className=" w-[59rem] max-w-none rounded-tl-4xl bg-stone-800 ring-1 ring-white/10 transition-all hover:scale-105"
            />
          </div>
        </div>
      </div>
    </FeatureCard>
  )
}

export function CuratedTracksCard(props: CardProps) {
  return (
    <FeatureCard {...props}>
      <div
        className={clsx(
          'absolute inset-0 top-[50%] ',
          'rounded-t-2xl bg-vanta-850 max-md:scale-110 md:top-[38%]',
        )}
      >
        <div className="flex w-[69%] items-center justify-between gap-3 rounded-b-lg rounded-t-xl  p-2 pl-3">
          <span className="flex items-center gap-1 text-xs font-semibold tracking-wide text-white">
            depth
          </span>
        </div>
        <div className="flex ">
          <RadioGroup className="flex ">
            {depthConfig.options.map((mockChallenge) => (
              <MockTrackChallenge
                key={`mock-${mockChallenge.value}`}
                challenge={mockChallenge}
              />
            ))}
          </RadioGroup>
        </div>

        <div className="flex w-[69%] items-center justify-between gap-3 rounded-b-lg rounded-t-xl  p-2 pl-3">
          <span className="flex items-center gap-1 text-xs font-semibold tracking-wide text-white">
            reasoning
          </span>
        </div>
        <div className="flex ">
          <RadioGroup className="flex ">
            {reasoningConfig.options.map((mockChallenge) => (
              <MockTrackChallenge
                key={`mock-${mockChallenge.value}`}
                challenge={mockChallenge}
              />
            ))}
          </RadioGroup>
        </div>
        <div className="flex w-[29%] items-center justify-between gap-3 rounded-b-lg rounded-t-xl  p-2 pl-3">
          <span className="flex items-center gap-1 text-xs font-semibold tracking-wide text-white">
            reasoning
          </span>
        </div>
        <div className="flex ">
          <RadioGroup className="flex ">
            {learningConfig.options.map((mockChallenge) => (
              <MockTrackChallenge
                key={`mock-${mockChallenge.value}`}
                challenge={mockChallenge}
              />
            ))}
          </RadioGroup>
        </div>
      </div>
    </FeatureCard>
  )
}

// note: should always be a subset of Challenge Type
interface MockTrackChallenge {
  difficulty
  id: number
  name: string
}

export const reasoningConfig = {
  description: 'Choose your reasoning style',
  options: [
    {
      id: 1,
      value: 'step-by-step',
      label: 'Step-By-Step',
      description: 'Logical flow with clear steps to understand the topic.',
    },
    {
      id: 2,
      value: 'big-picture',
      label: 'Big Picture',
      description:
        'Broad concepts and analogies to give you an overall understanding.',
    },
    {
      id: 3,
      value: 'real-world',
      label: 'Real-World',
      description: 'Practical examples to show how things work in real life.',
    },
  ],
}

export const depthConfig = {
  description: 'Select the level of detail you want in your course.',
  options: [
    {
      id: 1,
      value: 'basic',
      label: 'Basic',
      description:
        'Easy-to-understand topics, like what you’d learn in grade school.',
    },
    {
      id: 2,
      value: 'standard',
      label: 'Standard',
      description: 'College-level material that goes into more depth.',
    },
    {
      id: 3,
      value: 'expert',
      label: 'Expert',
      description: 'Advanced topics for graduate studies and research.',
    },
  ],
}

export const learningConfig = {
  description: 'Choose how you best like to learn.',
  options: [
    {
      id: 1,
      value: 'plain-english',
      label: 'Plain English',
      description: 'Simple explanations with the key points highlighted.',
    },
    {
      id: 2,
      value: 'hands-on',
      label: 'Hands On',
      description:
        'Learn by doing with code examples, exercises, and projects.',
    },
    {
      id: 3,
      value: 'in-depth',
      label: 'In Depth',
      description: 'Detailed insights with academic references and citations.',
    },
  ],
}

const mockChallenges: MockTrackChallenge[] = [
  {
    id: 1,
    name: 'Development',
    difficulty: 'BEGINNER',
  },
  {
    id: 2,
    name: 'Law',
    difficulty: 'EASY',
  },
  {
    id: 3,
    name: 'Pre Med',
    difficulty: 'MEDIUM',
  },
  {
    id: 4,
    name: 'Data Science',
    difficulty: 'HARD',
  },
  {
    id: 5,
    name: 'Marketing',
    difficulty: 'EXTREME',
  },
]

function useNumberCycler() {
  const [currentNumber, setCurrentNumber] = useState(0)
  const [dummy, setDummy] = useState(0)

  const increment = () => {
    setCurrentNumber((prevNumber) => {
      return (prevNumber + 1) % 4
    })

    setDummy((prev) => prev + 1)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentNumber((prevNumber) => {
        return (prevNumber + 1) % 4
      })
    }, 2000)

    return () => {
      clearInterval(intervalId)
    }
  }, [dummy])

  return {
    increment,
    currentNumber,
  }
}
