'use client'

import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { useRef, useState } from 'react'

import { cn } from '@/lib/utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium shadow ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground shadow-md hover:bg-primary/90',

        destructive:
          'bg-red-700 dark:bg-red-500 text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-white dark:bg-stone-900 text-secondary-foreground hover:bg-secondary/80',
        ghost: 'shadow-none ',
        link: 'text-primary underline-offset-4 shadow-none hover:underline',
      },
      size: {
        default: 'h-8 px-4 py-2',
        sm: 'max-w-[100px]',
        xs: '',
        lg: 'h-10 rounded-md px-2',
        icon: 'h-8 w-8 p-0',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

const glowButtonVariants = cva({
  variants: {
    size: {
      default: 'h-8 px-4 py-2',
      sm: 'w-[100px]',

      lg: 'h-11 rounded-md px-8',
      icon: 'h-8 w-8 p-0',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
})

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

export interface GlowButtonProps extends ButtonProps {
  isPro?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

const GlowButton = React.forwardRef<HTMLButtonElement, GlowButtonProps>(
  (
    { className, variant, isPro = false, disabled, size = 'lg', ...props },
    ref,
  ) => {
    return (
      <div
        className={cn(
          size === 'xs'
            ? 'w-full max-w-[12rem]'
            : size === 'sm'
            ? 'w-[150px]'
            : 'w-[250px]',
          ' relative  before:pointer-events-none before:absolute before:-inset-1 before:rounded-[11px] before:border before:border-vanta-100/20 before:opacity-0   before:transition dark:before:border-brand-400/70 dark:before:ring-2 dark:before:ring-brand-900/60',
          ' input-shadow-glow after:pointer-events-none after:absolute after:inset-px after:rounded-[7px] after:shadow-white/5 after:transition',
          !disabled &&
            'focus-within:before:opacity-100 focus-within:after:shadow-vanta-100/100 dark:after:shadow-white/5 dark:focus-within:after:shadow-brand-500/20',
          !disabled &&
            'hover:before:opacity-100 hover:after:shadow-vanta-300/10  dark:hover:after:shadow-brand-500/40',
        )}
      >
        <button
          className={cn(
            size === 'xs'
              ? 'w-full  max-w-[12rem]'
              : size === 'sm'
              ? 'w-[150px]'
              : 'w-[250px]',
            'glow-button font-semibold shadow-md',
            !disabled &&
              ' focus:outline-none  focus:ring-1 focus:ring-inset focus:ring-vanta-100/10 dark:focus:ring-brand-800/10',
            !disabled &&
              ' hover:outline-none  hover:ring-1 hover:ring-inset hover:ring-vanta-100/40  dark:hover:ring-brand-800/10',
            'disabled:cursor-not-allowed disabled:opacity-80   sm:leading-6 ',
            'dark:border dark:border-black/40 ',
            ' input-shadow rounded-lg  !outline-none',
            isPro
              ? 'relative border border-black/5 bg-brandBlue-500/80 px-3.5 py-2 text-white shadow-black/5 placeholder:text-vanta-800  focus:bg-white '
              : 'relative border border-black/5 bg-white/80 px-3.5 py-2 text-black shadow-black/5 placeholder:text-vanta-800  focus:bg-white ',
            ' dark:bg-vanta-700/60 dark:text-white dark:shadow-black/10 dark:placeholder:text-vanta-500',
            !disabled &&
              'dark:hover:bg-vanta-700/50 dark:focus:bg-vanta-700/50',
            disabled && 'cursor-default',
            className,
          )}
          ref={ref}
          {...props}
        />
      </div>
    )
  },
)
GlowButton.displayName = 'GlowButton'

export const GlowButtonRound = React.forwardRef<
  HTMLButtonElement,
  GlowButtonProps
>(
  (
    { className, variant, isPro = false, disabled, size = 'lg', ...props },
    ref,
  ) => {
    return (
      <div
        className={cn(
          size === 'xs'
            ? 'w-full max-w-[12rem]'
            : size === 'sm'
            ? 'w-[150px]'
            : 'w-[250px]',
          ' relative  before:pointer-events-none before:absolute before:-inset-1 before:rounded-full before:border before:border-vanta-100/20 before:opacity-0   before:transition dark:before:border-brand-400/70 dark:before:ring-2 dark:before:ring-brand-900/60',
          ' input-shadow-glow after:pointer-events-none after:absolute after:inset-px after:rounded-full after:shadow-white/5 after:transition',
          !disabled &&
            'focus-within:before:opacity-100 focus-within:after:shadow-vanta-100/100 dark:after:shadow-white/5 dark:focus-within:after:shadow-brand-500/20',
          !disabled &&
            'hover:before:opacity-100 hover:after:shadow-vanta-300/10  dark:hover:after:shadow-brand-500/40',
        )}
      >
        <button
          className={cn(
            size === 'xs'
              ? 'w-full  max-w-[12rem]'
              : size === 'sm'
              ? 'w-[150px]'
              : 'w-[250px]',
            'glow-button font-semibold shadow-md',
            !disabled &&
              ' focus:outline-none  focus:ring-1 focus:ring-inset focus:ring-vanta-100/10 dark:focus:ring-brand-800/10',
            !disabled &&
              ' hover:outline-none  hover:ring-1 hover:ring-inset hover:ring-vanta-100/40  dark:hover:ring-brand-800/10',
            'disabled:cursor-not-allowed disabled:opacity-80   sm:leading-6 ',
            'dark:border dark:border-black/40 ',
            ' input-shadow rounded-full  !outline-none',
            isPro
              ? 'relative border border-black/5 bg-brandBlue-500/80 px-3.5 py-2 text-white shadow-black/5 placeholder:text-vanta-800  focus:bg-white '
              : 'relative border border-black/5 bg-white/80 px-3.5 py-2 text-black shadow-black/5 placeholder:text-vanta-800  focus:bg-white ',
            ' dark:bg-vanta-700/60 dark:text-white dark:shadow-black/10 dark:placeholder:text-vanta-500',
            !disabled &&
              'dark:hover:bg-vanta-700/50 dark:focus:bg-vanta-700/50',
            disabled && 'cursor-default',
            className,
          )}
          ref={ref}
          {...props}
        />
      </div>
    )
  },
)
GlowButtonRound.displayName = 'GlowButtonRound'

const GlowButton2 = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, disabled, size, ...props }, ref) => {
    return (
      <div
        className={cn(
          ' relative w-full before:pointer-events-none before:absolute before:-inset-1 before:rounded-[11px] before:border before:border-stone-100/20 before:opacity-0   before:transition dark:before:border-stone-400/30 dark:before:ring-2 dark:before:ring-stone-900/40',
          ' input-shadow-glow after:pointer-events-none after:absolute after:inset-px after:rounded-[7px] after:shadow-white/5 after:transition',
          !disabled &&
            'focus-within:before:opacity-100 focus-within:after:shadow-stone-100/100 dark:after:shadow-white/5 dark:focus-within:after:shadow-stone-500/20',
          !disabled &&
            'hover:before:opacity-100 hover:after:shadow-stone-300/10  dark:hover:after:shadow-stone-500/20',
        )}
      >
        <button
          className={cn(
            'w-full font-semibold shadow-md',
            !disabled &&
              ' focus:outline-none  focus:ring-1 focus:ring-inset focus:ring-stone-100/10 dark:focus:ring-stone-800/10',
            !disabled &&
              ' hover:outline-none  hover:ring-1 hover:ring-inset hover:ring-stone-100/40  dark:hover:ring-stone-800/10',
            'disabled:cursor-not-allowed disabled:opacity-80   sm:leading-6 ',
            'dark:border dark:border-black/40 ',
            ' input-shadow rounded-lg  !outline-none',
            'relative border border-black/5 bg-white/80 px-3.5 py-2 text-black shadow-black/5 placeholder:text-stone-800  focus:bg-white ',
            ' dark:bg-black/60 dark:text-white dark:shadow-black/10 dark:placeholder:text-stone-500',
            !disabled && 'dark:hover:bg-black/70 dark:focus:bg-black/70',
            disabled && 'cursor-default',
            className,
          )}
          ref={ref}
          {...props}
        />
      </div>
    )
  },
)
GlowButton2.displayName = 'GlowButton'

export interface PillProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  text: string
}
const Pill = React.forwardRef<HTMLDivElement, PillProps>(
  ({ className, text, ...props }, ref) => {
    return (
      <div className="relative w-fit overflow-hidden">
        <div className="to-pink/0 pointer-events-none absolute left-[15%] top-0 z-10 block h-px w-[40%] select-none bg-gradient-to-r from-indigo-600/0 from-0% via-brand-600 via-50% to-100%"></div>
        <div className="pointer-events-none absolute left-[15%] top-0 z-10 block aspect-square w-[40%] -translate-y-1/2 select-none rounded-b-full bg-purple-600/10 blur-md"></div>
        <div
          className={cn(
            'active:scale-1 inline-flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-full border border-brand-600/40 bg-brand-600/5 bg-neutral-900 px-4 py-3 text-xs font-medium text-white backdrop-blur-3xl transition-colors hover:bg-brand-600/10 hover:bg-neutral-700 focus:border-brand-600 focus:outline-none focus:ring-0   focus:ring-neutral-400  focus:ring-offset-0 focus:ring-offset-neutral-900 disabled:pointer-events-none disabled:opacity-50 data-[state=open]:bg-neutral-800 sm:px-6 sm:py-[6px]',
            className,
          )}
          ref={ref}
          {...props}
        >
          {text}
        </div>
      </div>
    )
  },
)
Pill.displayName = 'Pill'

function LectureButton({ invert = false, className, children, ...props }) {
  className = cn(
    className,
    'inline-flex rounded-full px-4 py-1.5 text-sm font-semibold  transition input-shadow-glow',
    'bg-gradient-to-br from-neutral-100 md:hover:rotate-2 hover:duration-50 to-white dark:bg-gradient-to-br dark:from-vanta-600/90 dark:to-stone-800  text-black dark:text-white  border shadow-sm border-black/10 dark:border dark:border-white/5',
  )

  let inner = <span className="relative top-px">{children}</span>

  return (
    <button className={className} {...props}>
      {inner}
    </button>
  )
}

LectureButton.displayName = 'LectureButton'

const ButtonAnimatedGradient = React.forwardRef<HTMLDivElement, ButtonProps>(
  ({ className, children, ...props }, ref) => {
    const divRef = useRef<HTMLButtonElement>(null)
    const [isFocused, setIsFocused] = useState(false)
    const [position, setPosition] = useState({ x: 0, y: 0 })
    const [opacity, setOpacity] = useState(0)

    const handleMouseMove = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!divRef.current || isFocused) return

      const div = divRef.current
      const rect = div.getBoundingClientRect()

      setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top })
    }

    const handleFocus = () => {
      setIsFocused(true)
      setOpacity(1)
    }

    const handleBlur = () => {
      setIsFocused(false)
      setOpacity(0)
    }

    const handleMouseEnter = () => {
      setOpacity(1)
    }

    const handleMouseLeave = () => {
      setOpacity(0)
    }

    return (
      <>
        <button
          ref={divRef}
          onMouseMove={handleMouseMove}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="focus-ring-brand-400 relative inline-flex h-12 w-full items-center justify-center overflow-hidden  rounded-lg border border-brand-900/20 border-stone-800 bg-gradient-to-r  from-stone-800 to-stone-900  px-6 font-medium text-stone-50  transition-colors focus:outline-none focus:ring-2 focus:ring-stone-400 focus:ring-offset-2 focus:ring-offset-stone-50 dark:text-stone-300"
          {...props}
        >
          <div
            className="pointer-events-none absolute -inset-px opacity-0 transition duration-300"
            style={{
              opacity,
              background: `radial-gradient(300px circle at ${position.x}px ${position.y}px, rgba(255,255,255,.1), #0000000f)`,
            }}
          />
          {children}
        </button>
      </>
    )
  },
)

ButtonAnimatedGradient.displayName = 'ButtonAnimatedGradient'

export {
  Button,
  buttonVariants,
  GlowButton,
  Pill,
  LectureButton,
  ButtonAnimatedGradient,
}
