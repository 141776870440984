'use client'

import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const buttonVariants = cva('inline-flex items-center gap-x-1.5 ', {
  variants: {
    variant: {
      default:
        ' rounded-full font-medium text-stone-900 ring-1 ring-inset ring-stone-200 dark:ring-stone-700',
    },
    size: {
      default: 'px-2 py-1 text-xs',
      sm: 'px-2 py-1 text-xs',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
})

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  text: string
  color: string
}

export interface PillProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  text: string
}

const BadgeOld = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, color, text, size, asChild = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          'border border-neutral-600 text-xs text-stone-700 dark:text-stone-300',
        )}
        ref={ref}
        {...props}
      >
        <svg
          className={cn('h-1.5 w-1.5 fill-stone-500', color)}
          viewBox="0 0 6 6"
          aria-hidden="true"
        >
          <circle cx={3} cy={3} r={3} />
        </svg>
        {text}
      </Comp>
    )
  },
)
BadgeOld.displayName = 'BadgeOld'

const badgeVariants = cva(
  'inline-flex items-center rounded-md border border-stone-200 px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-stone-400 focus:ring-offset-2 dark:border-stone-800 dark:focus:ring-stone-800',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-stone-900 text-stone-50 shadow hover:bg-stone-900/80 dark:bg-stone-50 dark:text-stone-900 dark:hover:bg-stone-50/80',
        secondary:
          'inline-flex items-center rounded-md bg-stone-50 px-2 py-1 text-xs font-medium text-stone-600 ring-1 ring-inset ring-stone-500/10 dark:bg-stone-400/10 dark:text-stone-400 dark:ring-stone-400/20',
        active:
          'inline-flex items-center rounded-md bg-brand-50 px-2 py-1 text-xs font-medium text-brand-600 ring-1 ring-inset ring-brand-500/10 dark:bg-brand-400/10 dark:text-brand-400 dark:ring-brand-400/20',

        destructive:
          'border-transparent bg-red-500 text-stone-50 shadow hover:bg-red-500/80 dark:bg-red-900 dark:text-red-50 dark:hover:bg-red-900/80',
        outline: 'text-stone-950 dark:text-stone-50',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { BadgeOld, Badge, badgeVariants }
