'use client'

import { forwardRef, useState } from 'react'
import { useRouter } from 'next/navigation'
import { Session, User } from '@supabase/supabase-js'
import { CheckIcon } from '@radix-ui/react-icons'
// import { Check } from '@phosphor-icons/react';
import cn from 'classnames'

import { Database } from '@/types_db'

import { Loading } from '@/components/ui/icons'
import { FadeIn } from '@/components/animations/FadeIn'
import { Button, Pill } from '@/components/ui/button'

import { postData } from '@/lib/helpers'
import { getStripe } from '@/lib/user/stripe-client'

import { Card } from '@/components/ui/card'
import { SandpaperButton } from '@/components/cult/sandpaper-link'

type Product = Database['public']['Tables']['products']['Row']
type Price = Database['public']['Tables']['prices']['Row']
interface ProductWithPrices extends Product {
  prices: Price[]
}

interface Props {
  userId: string | undefined
  products: ProductWithPrices[]
  subscription: any
}

type Tier = {
  name: string
  id: string
  amount: string
  features: string[]
  order: number
  description?: string
  mostPopular: boolean
}

type Tiers = {
  Basic: Tier
  Standard: Tier
  Premium: Tier
  Fallback: Tier
  [key: string]: Tier
}

const GlowButton2 = forwardRef<HTMLButtonElement, any>(
  ({ className, variant, disabled, size, ...props }, ref) => {
    return (
      <div
        className={cn(
          ' relative w-full before:pointer-events-none before:absolute before:-inset-1 before:rounded-[11px] before:border  before:border-brandBlue-400/60   before:opacity-0 before:ring-2 before:ring-brandBlue-900/40 before:transition',
          ' input-shadow-glow after:pointer-events-none after:absolute after:inset-px after:rounded-[7px] after:shadow-white/5 after:transition',
          !disabled &&
            'after:shadow-white/5  focus-within:before:opacity-100 focus-within:after:shadow-brandBlue-500/20',
          !disabled &&
            'hover:before:opacity-100   hover:after:shadow-brandBlue-500/20',
        )}
      >
        <button
          className={cn(
            'w-full font-semibold shadow-md',
            !disabled &&
              ' focus:outline-none  focus:ring-1 focus:ring-inset  focus:ring-brandBlue-800/10',
            !disabled &&
              ' hover:outline-none  hover:ring-1 hover:ring-inset   hover:ring-brandBlue-800/10',
            'disabled:cursor-not-allowed disabled:opacity-80   sm:leading-6 ',
            'border border-black/40 ',
            ' input-shadow rounded-lg  !outline-none',
            'relative    px-3.5 py-2  shadow-black/5 ',
            ' bg-vanta-700/60 text-white shadow-black/10 placeholder:text-stone-500',
            !disabled && 'hover:bg-vanta-800/60 focus:bg-vanta-800/60',
            disabled && 'cursor-default',
            className,
          )}
          ref={ref}
          {...props}
        />
      </div>
    )
  },
)
GlowButton2.displayName = 'GlowButton'

export function ChatBubble() {
  return (
    <Card
      key="1"
      className="relative mx-auto flex w-full max-w-4xl items-center space-x-4 rounded-3xl bg-white p-6 shadow-lg"
    >
      <div className="flex-1">
        <p className="text-lg font-semibold">Continue your AI journey.</p>
      </div>
      <div className="flex space-x-2">
        <Button className="rounded-lg bg-gray-900 px-4 py-2 text-white">
          Generate UI with v0.dev
        </Button>
        <Button className="rounded-lg border border-gray-300 bg-white px-4 py-2 text-gray-900">
          Get a Demo
        </Button>
      </div>
      <svg
        aria-hidden="true"
        className="absolute hidden lg:bottom-[-90px] lg:left-[-19px] lg:block lg:h-[97px] lg:w-[105px]"
        fill="none"
        height="97"
        viewBox="0 0 105 90"
        width="105"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.8208 0.990723H104.004C103.473 49.224 64.2081 88.1605 15.8491 88.1605H1.79729C12.9183 70.2398 18.8208 49.5576 18.8208 28.4427V0.990723Z"
          className="fill-white stroke-gray-300"
        ></path>
      </svg>
    </Card>
  )
}

export function TailwindStyledDiv() {
  return (
    <div className="relative   mr-px rounded-3xl border border-black/10 bg-white lg:rounded-full">
      <div className="lg:pr-18 relative  flex h-48 flex-col items-center rounded-full py-5 lg:flex-row lg:pl-16">
        <h2
          className="mb-12 text-2xl font-semibold text-gray-900 lg:mb-0 lg:text-5xl"
          style={{ letterSpacing: '-0.96px' }}
        >
          Continue your AI journey.
        </h2>
        <div className="relative flex gap-4 lg:ml-auto">
          {/* Decorative elements could be added here */}
          {/* ... Other decorative elements go here ... */}

          <a
            role="link"
            href="https://v0.dev/"
            className="inline-block rounded-full bg-black px-10 py-2.5 text-white shadow-md hover:bg-opacity-90 focus:outline-none focus:ring lg:px-14 lg:py-3"
          >
            <span className="hidden lg:block">Generate UI with v0.dev</span>
            <span className="lg:hidden">Try v0.dev</span>
          </a>
          <a
            role="link"
            href="/contact/sales"
            className="inline-block rounded-full bg-white px-10 py-2.5 text-black shadow-md hover:bg-opacity-90 focus:outline-none focus:ring lg:px-14 lg:py-3"
          >
            Get a Demo
          </a>
        </div>
      </div>
      <div className="absolute z-10 h-24 w-14 rounded-l-4xl bg-white lg:bottom-[30px] lg:left-[2px]"></div>
      <svg
        aria-hidden="true"
        className="absolute  hidden rotate-[29deg] lg:bottom-[7px] lg:left-[-44px] lg:block lg:h-[77px] lg:w-[100px]"
        fill="none"
        height="97"
        viewBox="0 0 105 90"
        width="105"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.8208 0.990723H104.004C103.473 49.224 64.2081 88.1605 15.8491 88.1605H1.79729C12.9183 70.2398 18.8208 49.5576 18.8208 28.4427V0.990723Z"
          className="fill-white stroke-black/10"
        ></path>
      </svg>
    </div>
  )
}

function TailwindStyledDi2v() {
  return (
    <div className="bg-background-100 relative mr-px rounded-3xl lg:rounded-full">
      <div className="lg:pr-18 relative flex h-48 flex-col items-center rounded-full py-5 lg:flex-row lg:pl-16">
        <h2 className="mb-12 text-xl font-semibold leading-normal tracking-tighter text-gray-900 lg:mb-0 lg:text-5xl lg:leading-snug lg:tracking-tight">
          Continue your AI journey.
        </h2>
        <div className="relative flex gap-4 lg:ml-auto">
          {/* ... Other decorative elements go here ... */}
          <a
            role="link"
            href="https://v0.dev/"
            className="geist-button w-31 sm:h-10 sm:px-2.5 md:h-10 md:px-2.5 lg:h-12 lg:w-auto lg:px-3.5"
          >
            <span className="geist-content">
              <span className="hidden lg:block">Generate UI with v0.dev</span>
              <span className="lg:hidden">Try v0.dev</span>
            </span>
          </a>
          <a
            role="link"
            href="/contact/sales"
            className="geist-button-secondary w-31 sm:h-10 sm:px-2.5 md:h-10 md:px-2.5 lg:h-12 lg:w-auto lg:px-3.5"
          >
            <span className="geist-content">Get a Demo</span>
          </a>
        </div>
      </div>
    </div>
  )
}

const tiers: Tiers = {
  Pro: {
    name: 'Pro',
    amount: '200 Credits',
    id: 'tier-freelancer',
    order: 3,
    features: [
      'Access to AI Notes',
      'Create Private study guides',
      'Get new features first',
    ],
    mostPopular: true,
  },
  Free: {
    name: 'Creator',
    id: 'tier-startup',
    amount: '100 Credits',
    order: 2,
    features: [
      'Access all public study guides',
      'Generate 40 custom study guides',
      'Remix public study guides',
    ],
    mostPopular: true,
  },
  //   Reader: {
  //     name: 'Reader',
  //     amount: '30 Credits',
  //     id: 'tier-enterprise',
  //     order: 1,
  //     features: ['Access all public courses'],
  //     mostPopular: false,
  //   },
  // @ts-ignore
  Fallback: {
    name: 'Standard',
    id: 'tier-startup',
    features: [
      'All AI products',
      'Dev Discord Access',
      'Feature Request',
      '24-hour support response time',
    ],
    mostPopular: false,
  },
}

interface TierComponentProps {
  product: ProductWithPrices
  tier: Tiers[string]
  price: Price
  handleCheckout: (price: Price) => void
  priceIdLoading: Price['id'] | undefined
}

function TierComponent({
  tier,
  price,
  handleCheckout,
  priceIdLoading,
}: TierComponentProps) {
  const isMostPopular = tier?.mostPopular
  const priceString = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: price?.currency ?? 'USD',
    minimumFractionDigits: 0,
  }).format((price?.unit_amount || 0) / 100)

  return (
    <div
      className={cn(
        isMostPopular
          ? 'bg-neutral-900 dark:bg-brand-500 lg:z-10 '
          : 'bg-white first:rounded-tr-none last:rounded-tl-none dark:bg-vanta-850 lg:mt-8',
        'flex flex-col justify-between rounded-3xl p-8 ring-1  ring-vanta-950  xl:p-10',
      )}
    >
      <div className="pb-6">
        <div className="ml-auto flex justify-end">
          {isMostPopular ? (
            <Pill
              text="Most popular"
              className="dark:bg-brand-600 dark:text-white"
            />
          ) : null}
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <h3
            className={cn(
              isMostPopular
                ? 'text-neutral-300 dark:text-black'
                : 'text-gray-900 dark:text-vanta-100',
              'text-xl font-semibold leading-8',
            )}
          >
            {tier?.name}
          </h3>
        </div>
        <p
          className={cn(
            isMostPopular
              ? 'text-neutral-300/80'
              : 'text-gray-600 dark:text-vanta-200',
            'mt-4 text-sm leading-6 ',
          )}
        >
          {tier?.description}
        </p>
        <p className="mt-6 flex items-baseline gap-x-1">
          <span
            className={cn(
              isMostPopular
                ? 'text-neutral-100/80'
                : 'text-gray-900 dark:text-white',
              'text-4xl font-bold tracking-tight',
            )}
          >
            {priceString}
          </span>
          <span
            className={cn(
              isMostPopular
                ? 'text-neutral-200/80'
                : 'text-gray-800 dark:text-white/80',
              'text-sm ',
            )}
          ></span>
        </p>
        <ul
          role="list"
          className={cn(
            isMostPopular
              ? 'text-neutral-300/80 dark:text-black'
              : 'text-gray-600 dark:text-vanta-300',
            'mb-2 mt-8 space-y-3 text-sm leading-6',
          )}
        >
          {tier?.features?.map((feature: string) => (
            <li key={feature} className="flex gap-x-3">
              <CheckIcon
                className={cn(
                  isMostPopular
                    ? 'text-brand-500 dark:text-black'
                    : 'text-black dark:text-white',
                  'h-6 w-5 flex-none',
                )}
                aria-hidden="true"
              />
              {feature}
            </li>
          ))}
        </ul>
      </div>

      <GlowButton2
        disabled={false}
        className=" "
        onClick={() => handleCheckout(price)}
      >
        <FadeIn key={priceIdLoading}>
          <div className=" flex  items-center justify-center text-base font-semibold">
            {priceIdLoading === price?.id ? (
              <Loading className="mr-2 h-5 w-5 animate-spin stroke-brand-500/80  dark:stroke-brand-400 " />
            ) : (
              <span>Subscribe Now</span>
            )}
          </div>
        </FadeIn>
      </GlowButton2>
    </div>
  )
}

function FreeTier({}) {
  const isMostPopular = false
  const priceString = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format((0 || 0) / 100)

  const features = ['Access to all public courses', 'Create public courses']

  return (
    <div
      className={cn(
        isMostPopular
          ? 'bg-neutral-900 dark:bg-brand-500 lg:z-10 lg:rounded-b-none'
          : 'bg-white first:rounded-tr-none last:rounded-tl-none dark:bg-vanta-850 lg:mt-8',
        'flex flex-col justify-between rounded-3xl p-8 ring-1 ring-gray-200 dark:ring-vanta-950 lg:rounded-b-none xl:p-10',
      )}
    >
      <div className="pb-6">
        <div className="flex items-center justify-between gap-x-4">
          <h3
            className={cn(
              isMostPopular
                ? 'text-neutral-300 dark:text-black'
                : 'text-gray-900 dark:text-vanta-100',
              'text-xl font-semibold leading-8',
            )}
          >
            Free
          </h3>
        </div>
        <p
          className={cn(
            isMostPopular
              ? 'text-neutral-300/80'
              : 'text-gray-600 dark:text-vanta-200',
            'mt-4 text-sm leading-6 ',
          )}
        ></p>
        <p className="mt-6 flex items-baseline gap-x-1">
          <span
            className={cn(
              isMostPopular
                ? 'text-neutral-100/80'
                : 'text-gray-900 dark:text-white',
              'text-4xl font-bold tracking-tight',
            )}
          >
            {priceString}
          </span>
          <span
            className={cn(
              isMostPopular
                ? 'text-neutral-200/80'
                : 'text-gray-800 dark:text-white/80',
              'text-sm ',
            )}
          ></span>
        </p>
        <ul
          role="list"
          className={cn(
            isMostPopular
              ? 'text-neutral-300/80 dark:text-black'
              : 'text-gray-600 dark:text-vanta-300',
            'mb-2 mt-8 space-y-3 text-sm leading-6',
          )}
        >
          {features?.map((feature: string) => (
            <li key={feature} className="flex gap-x-3">
              <CheckIcon
                className={cn(
                  isMostPopular
                    ? 'text-brand-500 dark:text-black'
                    : 'text-black dark:text-white',
                  'h-6 w-5 flex-none',
                )}
                aria-hidden="true"
              />
              {feature}
            </li>
          ))}
        </ul>
      </div>
      {/* 
      <GlowButton
        disabled={false}
        className=" "
        onClick={() => handleCheckout(price)}
      >
        <FadeIn key={priceIdLoading}>
          <div className=" flex  items-center justify-center text-base font-semibold">
            {priceIdLoading === price?.id ? (
              <Loading className="mr-2 h-5 w-5 animate-spin stroke-brand-500/80  dark:stroke-brand-400 " />
            ) : (
              <span>Subscribe Now</span>
            )}
          </div>
        </FadeIn>
      </GlowButton> */}
    </div>
  )
}
function Simple({}) {
  const isMostPopular = false
  const priceString = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format((0 || 0) / 100)

  const features = ['Access to all public courses', 'Create public courses']

  return (
    <section>
      <div className="h-42 absolute inset-x-0 -top-64 mx-auto w-32 justify-center rounded-lg bg-gradient-to-r from-vanta-50 via-vanta-200 to-vanta-600 opacity-50 blur-3xl lg:h-96 lg:w-96"></div>
      <div className="relative mx-auto max-w-7xl px-8 py-24 md:px-12 lg:px-32 lg:pt-56">
        <div className="grid grid-cols-1 items-center gap-12 lg:grid-cols-2 lg:gap-24">
          <div>
            <h1 className="bg-gradient-to-r from-vanta-50 via-vanta-300 to-vanta-600 bg-clip-text pb-2 font-display text-4xl font-normal tracking-tight text-transparent lg:text-6xl">
              Always know what you’ll pay, semitransparent pricing for some
            </h1>
            <p className="mt-8 text-vanta-300">
              Choose a plan that works the best for you and your team. Start
              small, upgrade when you need to.
            </p>
          </div>
          <div className="flex flex-col rounded-3xl bg-gradient-to-t from-white/20 p-2 ring-1 ring-white/10">
            <div className="h-full rounded-2xl bg-card/80 p-8 shadow-massive ring-white/10 backdrop-blur-2xl">
              <h3 className="mt-5 font-display text-xl text-white">
                <span className="text-3xl lg:text-6xl">$99</span>
              </h3>
              <p className="mt-2 text-base text-white">
                For medium-sized businesses
              </p>
              <ul
                role="list"
                className="order-last mt-8 flex flex-col gap-y-3 text-sm text-vanta-300"
              >
                <li className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-discount-check h-4 w-4"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
                    <path d="M9 12l2 2l4 -4"></path>
                  </svg>
                  <span className="ml-4"> Full Access to Course Content</span>
                </li>
                <li className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-discount-check h-4 w-4"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
                    <path d="M9 12l2 2l4 -4"></path>
                  </svg>
                  <span className="ml-4">Certificate upon Completion</span>
                </li>
                <li className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-discount-check h-4 w-4"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
                    <path d="M9 12l2 2l4 -4"></path>
                  </svg>
                  <span className="ml-4">Lifetime Access to Materials</span>
                </li>
                <li className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-discount-check h-4 w-4"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
                    <path d="M9 12l2 2l4 -4"></path>
                  </svg>
                  <span className="ml-4">Email Support</span>
                </li>
                <li className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-discount-check h-4 w-4"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
                    <path d="M9 12l2 2l4 -4"></path>
                  </svg>
                  <span className="ml-4">Multiple integrations</span>
                </li>
                <li className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-discount-check h-4 w-4"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
                    <path d="M9 12l2 2l4 -4"></path>
                  </svg>
                  <span className="ml-4">Customization options</span>
                </li>
                <li className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-discount-check h-4 w-4"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
                    <path d="M9 12l2 2l4 -4"></path>
                  </svg>
                  <span className="ml-4">99.9% SLA</span>
                </li>
              </ul>
              <div className="mt-8">
                <a
                  className="flex h-10 items-center justify-center rounded-lg bg-gradient-to-b from-vanta-300 via-vanta-400 to-vanta-500 px-4 py-2 text-sm text-white transition-all hover:to-vanta-600"
                  aria-label="Indie hacker tier"
                  href="/register"
                >
                  Get started
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function Simple2({ price, product, priceIdLoading, handleCheckout }) {
  const isMostPopular = false
  const priceString = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: price?.currency ?? 'USD',
    minimumFractionDigits: 0,
  }).format((price?.unit_amount || 0) / 100)
  const features = ['Access to all public courses', 'Create public courses']

  return (
    <section className="lg:p-8">
      <div className="relative mx-auto w-full items-center px-8 py-12 md:px-12 lg:px-16 xl:px-36 2xl:max-w-7xl">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-brandBlue-400">
            Upgrade
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-vanta-100/90 sm:text-7xl">
            Go Pro
          </p>
          {/* <p className="mt-2 max-w-2xl text-base font-bold  tracking-wide text-vanta-900/70 dark:text-vanta-100 sm:text-3xl">
            now you{' '}
            <span className="font-brand text-4xl font-bold tracking-wider text-brand-400">
              gnow.
            </span>
          </p> */}
        </div>

        <div className="mt-8 pb-12 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
          <div className="mx-auto w-full items-center">
            <div className="rounded-3xl bg-stone-900/80 p-5 shadow-inset lg:p-0">
              <div className="lg:max-w-non mx-auto gap-2 space-y-3 p-2 lg:mx-0 lg:flex lg:space-y-0">
                <div className="rounded-3xl border-white/5 from-stone-900 to-vanta-700 sm:p-10 lg:flex-auto lg:border lg:bg-gradient-to-tr">
                  <h3 className="text-2xl font-medium tracking-tight text-white">
                    Gnow, grow
                  </h3>
                  <p className="mt-6 text-zinc-300">
                    Don't just memorize it, gnow it. All access to all knowledge
                    tools.
                  </p>
                  <div className="mt-10 flex items-center gap-x-4">
                    <h4 className="flex-none text-sm font-semibold text-white">
                      We include
                    </h4>
                    <div className="h-px flex-auto bg-white/20"></div>
                  </div>
                  <ul
                    role="list"
                    className="mt-8 grid grid-cols-1 gap-4 text-sm text-white sm:grid-cols-2 sm:gap-6"
                  >
                    <li className="flex gap-x-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-check"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M5 12l5 5l10 -10"></path>
                      </svg>
                      Generate Private Courses
                    </li>
                    <li className="flex gap-x-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-check"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M5 12l5 5l10 -10"></path>
                      </svg>
                      Premium AI Tools
                    </li>
                    <li className="flex gap-x-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-check"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M5 12l5 5l10 -10"></path>
                      </svg>
                      Notion Style AI Note Taking
                    </li>
                    <li className="flex gap-x-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-check"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M5 12l5 5l10 -10"></path>
                      </svg>
                      First access to new features
                    </li>
                  </ul>
                </div>
                <div className="-mt-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                  <div className="rounded-3xl  py-10 text-center ring-1 ring-inset ring-white/5  md:text-left lg:flex lg:flex-col lg:justify-center lg:py-16">
                    <div className="gird-cols-1 mx-auto grid px-8 md:grid-cols-2 lg:max-w-xs lg:grid-cols-1">
                      <div>
                        <p className="text-2xl font-bold text-vanta-100 lg:text-center">
                          Pro
                        </p>
                        <p className="mt-6 flex items-baseline justify-center gap-x-2 md:justify-start lg:justify-center">
                          <span className="text-5xl font-bold tracking-tight text-white">
                            {priceString}
                          </span>
                          <span className="text-sm font-semibold tracking-wide text-white">
                            /month
                          </span>
                        </p>
                      </div>

                      <div className="mt-8 w-full md:mt-20 md:min-w-[14rem]">
                        <SandpaperButton
                          className=" "
                          onClick={() => handleCheckout(price)}
                        >
                          <FadeIn key={priceIdLoading}>
                            <div className=" flex  w-full items-center justify-center text-base font-semibold">
                              {priceIdLoading === price?.id ? (
                                <Loading className="mr-2 h-5 w-5 animate-spin stroke-brand-500/80  dark:stroke-brand-400 " />
                              ) : (
                                <span>Start today</span>
                              )}
                            </div>
                          </FadeIn>
                        </SandpaperButton>
                        {/* <a
                          href="#"
                          className="mt-10 inline-flex w-full items-center justify-center rounded-full border border-transparent bg-white px-4 py-2 text-sm text-black ring-1 ring-transparent duration-200 hover:bg-white/10 hover:text-white focus:ring-2 focus:ring-white focus:ring-offset-2 md:mt-0 lg:mt-10"
                        >
                          Get access
                        </a>
                        <p className="mt-6 text-center text-xs leading-5 text-white">
                          Simplify the reimbursement process for your company
                          with readily accessible invoices and receipts.
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default function Pricing({ userId, products, subscription }: Props) {
  console.log({ userId, products, subscription })
  const router = useRouter()
  const isLoggedIn = userId

  const [animate, setAnimate] = useState(false)
  const [priceIdLoading, setPriceIdLoading] = useState<string | undefined>('')

  const handleCheckout = async (price: Price) => {
    setPriceIdLoading(price.id)
    if (!isLoggedIn) {
      return router.push('/auth/sign-in')
    }

    try {
      const { sessionId } = await postData({
        url: '/api/create-checkout-session',
        data: { price },
      })

      const stripe = await getStripe()
      stripe?.redirectToCheckout({ sessionId })
    } catch (error) {
      return alert((error as Error)?.message)
    } finally {
      setPriceIdLoading(undefined)
    }
  }

  const product = products[0]

  console.log(product.prices[0])

  return (
    // <section className="mx-24 rounded-2xl bg-brand-500">
    //   <div className="mx-auto flex max-w-6xl flex-col items-center justify-center px-4 py-6 sm:px-6 sm:py-8 md:flex-row md:justify-between ">
    //     <div className="sm:align-center sm:flex sm:flex-col ">
    //       <div className="mx-auto max-w-4xl text-center">
    //         <h2 className="text-brandPink-600 text-base font-semibold leading-7">
    //           Upgrade
    //         </h2>
    //         <p className="mt-2 text-4xl font-bold tracking-tight text-vanta-900 dark:text-vanta-100/90 sm:text-6xl">
    //           Go Pro.
    //         </p>
    //         <p className="mt-2 max-w-2xl text-base font-bold  tracking-wide text-vanta-900/70 dark:text-vanta-100 sm:text-3xl">
    //           Create private courses{' '}
    //         </p>
    //       </div>
    //     </div>
    //     <div className=" sm:py-12 ">
    //       <div className="mx-auto max-w-7xl px-6 lg:px-8">
    //         <div className="isolate mx-auto mt-12 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
    //           <div className=" ">
    //             <Simple />
    //           </div>
    //           {products.map((product) => {
    //             const price = product?.prices[0]
    //             const tierKey = product?.name ?? 'Fallback'
    //             const tier = tiers[tierKey as keyof typeof tiers]

    //             console.log('[product]', product)
    //             console.log('[tierKey]', tierKey)

    //             return (
    //               <TierComponent
    //                 product={product}
    //                 tier={tier}
    //                 price={price}
    //                 handleCheckout={() => handleCheckout(price)}
    //                 priceIdLoading={priceIdLoading}
    //               />
    //             )
    //           })}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <section className="  -mt-1 bg-gradient-to-b from-vanta-900 to-vanta-800 md:mx-24 md:rounded-2xl">
      <Simple2
        price={product.prices[0]}
        product={product}
        priceIdLoading={priceIdLoading}
        handleCheckout={handleCheckout}
      />
    </section>
  )
}
