import { forwardRef } from 'react'

import cn from 'classnames'

import Link from 'next/link'

const SandpaperLink = forwardRef<any, any>(
  ({ className, variant, href, size, ...props }, ref) => {
    return (
      <Link href={href}>
        <div
          style={
            {
              // width: '250px',
              // height: '250px',
              // filter: 'contrast(110%) brightness(100%)',
              // background: `linear-gradient(197deg, rgba(103,75,236,0.77), rgba(108,61,236,0.63)),
              //              url("data:image/svg+xml,%3Csvg viewBox='0 0 21 21' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='5.33' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
            }
          }
          className={cn(
            ' relative w-full before:pointer-events-none before:absolute before:-inset-1 before:rounded-[11px] before:border  before:border-brandBlue-400/60   before:opacity-0 before:ring-2 before:ring-brandBlue-900/10 before:transition',
            ' input-shadow-glow after:pointer-events-none after:absolute after:inset-px after:rounded-[7px] after:shadow-white/5 after:transition',
            'after:shadow-white/5  focus-within:before:opacity-100 focus-within:after:shadow-brandBlue-500/20',
            'hover:before:opacity-100   hover:after:shadow-brandBlue-500/20',
          )}
        >
          <button
            style={{
              filter: 'contrast(110%) brightness(100%)',
              background: `linear-gradient(197deg, rgba(103,75,236,0.77), rgba(108,61,236,0.63)),
                             url("data:image/svg+xml,%3Csvg viewBox='0 0 21 21' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='5.33' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,

              // backgroundImage:
              //   'repeating-radial-gradient( circle at 0 0, transparent 0, #606060 4px ), repeating-linear-gradient( #f7dcdc55, #f7dcdc )',
            }}
            className={cn(
              'w-full font-semibold shadow-md',
              ' focus:outline-none  focus:ring-1 focus:ring-inset  focus:ring-brandBlue-800/10',
              ' hover:outline-none  hover:ring-1 hover:ring-inset   hover:ring-brandBlue-800/10',
              'disabled:cursor-not-allowed disabled:opacity-80   sm:leading-6 ',
              'border border-black/40 ',
              ' input-shadow rounded-lg  !outline-none',
              'relative    px-3.5 py-2  shadow-black/5 ',
              ' bg-vanta-700/60 text-white shadow-black/10 placeholder:text-stone-500',
              'hover:bg-vanta-800/60 focus:bg-vanta-800/60',
              className,
            )}
            ref={ref}
            {...props}
          />
        </div>
      </Link>
    )
  },
)
SandpaperLink.displayName = 'GlowLink'

const SandpaperButton = forwardRef<HTMLButtonElement, any>(
  ({ className, variant, disabled, size, ...props }, ref) => {
    return (
      <div
        className={cn(
          ' relative w-full before:pointer-events-none before:absolute before:-inset-1 before:rounded-[11px] before:border  before:border-brandBlue-400/60   before:opacity-0 before:ring-2 before:ring-brandBlue-900/40 before:transition',
          ' input-shadow-glow after:pointer-events-none after:absolute after:inset-px after:rounded-[7px] after:shadow-white/5 after:transition',
          !disabled &&
            'after:shadow-white/5  focus-within:before:opacity-100 focus-within:after:shadow-brandBlue-500/20',
          !disabled &&
            'hover:before:opacity-100   hover:after:shadow-brandBlue-500/20',
        )}
      >
        <button
          style={{
            filter: 'contrast(110%) brightness(100%)',
            background: `linear-gradient(197deg, rgba(103,75,236,0.77), rgba(108,61,236,0.63)),
                             url("data:image/svg+xml,%3Csvg viewBox='0 0 21 21' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='5.33' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,

            // backgroundImage:
            //   'repeating-radial-gradient( circle at 0 0, transparent 0, #606060 4px ), repeating-linear-gradient( #f7dcdc55, #f7dcdc )',
          }}
          className={cn(
            'w-full font-semibold shadow-md',
            !disabled &&
              ' focus:outline-none  focus:ring-1 focus:ring-inset  focus:ring-brandBlue-800/10',
            !disabled &&
              ' hover:outline-none  hover:ring-1 hover:ring-inset   hover:ring-brandBlue-800/10',
            'disabled:cursor-not-allowed disabled:opacity-80   sm:leading-6 ',
            'border border-black/40 ',
            ' input-shadow rounded-lg  !outline-none',
            'relative    px-3.5 py-2  shadow-black/5 ',
            ' bg-vanta-700/60 text-white shadow-black/10 placeholder:text-stone-500',
            !disabled && 'hover:bg-vanta-800/60 focus:bg-vanta-800/60',
            disabled && 'cursor-default',
            className,
          )}
          ref={ref}
          {...props}
        />
      </div>
    )
  },
)
SandpaperButton.displayName = 'SandpaperButton'

export { SandpaperLink, SandpaperButton }
