'use client'

import { IconCheck } from '@/components/ui/icons'
import { useIsMobile } from '@/lib/hooks/use-is-mobile'
import Balancer from 'react-wrap-balancer'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Label } from '@/components/ui/label'

// import type { Challenge, Submission } from '@repo/db/types'
import { Badge } from '@/components/ui/badge'
// import { DifficultyBadge } from '@/components/ui/difficulty-badge'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'

const COLORS_BY_DIFFICULTY = {
  BEGINNER: 'dark:bg-difficulty-beginner-dark bg-difficulty-beginner',
  EASY: 'dark:bg-difficulty-easy-dark bg-difficulty-easy',
  MEDIUM: 'dark:bg-difficulty-medium-dark bg-difficulty-medium',
  HARD: 'dark:bg-difficulty-hard-dark bg-difficulty-hard',
  EXTREME: 'dark:bg-difficulty-extreme-dark bg-difficulty-extreme',
}

export function DifficultyBadge({ difficulty }) {
  return (
    <Badge
      className={`duration-300 ${COLORS_BY_DIFFICULTY[difficulty]} text-white dark:text-black`}
    >
      {difficulty}
    </Badge>
  )
}

const BGS_BY_DIFFICULTY = {
  BEGINNER: 'to-difficulty-beginner/20 dark:to-difficulty-beginner-dark/20',
  EASY: 'to-difficulty-easy/20 dark:to-difficulty-easy-dark/20',
  MEDIUM: 'to-difficulty-medium/20 dark:to-difficulty-medium-dark/20',
  HARD: 'to-difficulty-hard/20 dark:to-difficulty-hard-dark/20',
  EXTREME: 'to-difficulty-extreme/20 dark:to-difficulty-extreme-dark/20',
} as const

export function TrackChallenge({
  challenge,
  isInProgress,
  isCompleted,
  isSelected = false,
  isCompact = false,
}) {
  const isMobile = useIsMobile()

  return (
    <label
      htmlFor={challenge.id.toString()}
      className="group/challenge flex cursor-pointer flex-col items-center pt-2"
    >
      <div
        className={cn(
          BGS_BY_DIFFICULTY[challenge.difficulty],
          'flex w-full items-center justify-between gap-3 rounded-lg',
          'bg-gradient-to-r from-neutral-500/10 from-70% to-100% dark:from-neutral-500/20',
          'p-4 py-2 text-black/90 duration-300 group-active/challenge:bg-neutral-500/40 group-active/challenge:duration-75 dark:text-white/90 sm:py-4',
          {
            'group-hover/challenge:scale-105 group-hover/challenge:rounded-xl group-hover/challenge:bg-neutral-500/20':
              !isMobile,
          },
        )}
      >
        <div className="w-full flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-3">
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="relative hidden items-center gap-3 md:flex md:flex-row ">
                    <input
                      className="peer hidden appearance-none"
                      type="checkbox"
                      id={challenge.id.toString()}
                      checked={isCompleted || isInProgress}
                      readOnly
                    />
                    <div
                      className={cn(
                        'h-5 w-5 rounded-full border border-black/70 bg-black/10 duration-75 peer-checked:border-transparent dark:border-white/50 dark:bg-white/10',
                        {
                          'peer-checked:bg-green-600/80 peer-checked:dark:bg-green-300/80':
                            isCompleted,
                          'peer-checked:bg-orange-600/80 peer-checked:dark:bg-orange-300/80':
                            isInProgress,
                        },
                      )}
                    />
                    {isCompleted ? (
                      <IconCheck className="absolute left-1 my-auto h-3 w-3 scale-0 stroke-[4] text-white duration-300 peer-checked:scale-100 dark:text-black" />
                    ) : null}
                    {isInProgress ? (
                      <IconCheck className="absolute left-1 my-auto h-3 w-3 scale-0 stroke-[4] text-white duration-300 peer-checked:scale-100 dark:text-black" />
                    ) : null}
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>
                    {isCompleted
                      ? 'Completed'
                      : isInProgress
                      ? 'Attempted'
                      : 'Todo'}
                  </p>
                </TooltipContent>
              </Tooltip>
              <div className="flex flex-col items-start gap-3 md:flex-row">
                <div className="flex flex-col gap-2">
                  <span className={cn({ 'text-primary': isSelected })}>
                    {challenge.name}
                  </span>
                  <div className="flex flex-row gap-3 md:hidden">
                    <DifficultyBadge difficulty={challenge.difficulty} />
                    {isCompleted && isMobile ? (
                      <Badge variant="outline">Completed</Badge>
                    ) : null}
                  </div>
                </div>
                {Boolean(!isCompact) && (
                  <span className="text-muted-foreground">
                    {challenge.shortDescription.substring(0, 60).concat('...')}
                  </span>
                )}
              </div>
            </div>
            <div className="hidden md:block">
              <DifficultyBadge difficulty={challenge.difficulty} />
            </div>
          </div>
        </div>
      </div>
    </label>
  )
}

// million-ignore
export function MockTrackChallenge({ challenge }: { challenge }) {
  const isMobile = useIsMobile()
  return (
    // <Label
    //   key={challenge.name}
    //   htmlFor={challenge.name}
    //   className={cn(
    //     'inline-flex flex-col items-start justify-center',
    //     ' w-28    cursor-pointer space-y-3 rounded-3xl border-black/5 border-stone-200 px-4 py-3 text-stone-950 shadow-md  sm:h-full      lg:border',
    //     'bg-gradient-to-tr  from-ash-50  to-mirage-100',
    //     'transition hover:translate-y-[.9px] hover:bg-gradient-to-tr hover:from-ash-50 hover:to-white',
    //     'dark:border-white/5 dark:bg-gradient-to-tr dark:from-ash-950  dark:to-mirage-950 dark:text-stone-50 ',
    //     'dark:hover:bg-gradient-t dark:[&:has([data-state=checked])]:bg-gradient-tb dark:hover:from-black/90  dark:hover:to-ash-900 dark:[&:has([data-state=checked])]:from-ash-900 dark:[&:has([data-state=checked])]:to-mirage-900',
    //     '[&:has([data-state=checked])]:border-black/10  [&:has([data-state=checked])]:text-vanta-950 dark:[&:has([data-state=checked])]:text-white ',
    //     '[&:has([data-state=checked])]:bg-gradient-to-br [&:has([data-state=checked])]:from-ash-100 [&:has([data-state=checked])]:to-brand-400 ',
    //   )}
    // >
    //   <RadioGroupItem
    //     className="sr-only"
    //     type="checkbox"
    //     id={challenge.value.toString()}
    //   />
    //   <span className="text-md mb-4 space-y-2 font-bold leading-none tracking-tight ">
    //     {/* @ts-ignore */}
    //     <Balancer>{challenge.name}</Balancer>
    //   </span>
    //   {/* @ts-ignore */}
    //   {challenge?.description ? (
    //     <p className="text-sm leading-tight text-vanta-850   dark:text-stone-200">
    //       {/* @ts-ignore */}
    //       <Balancer>{challenge?.description}</Balancer>
    //     </p>
    //   ) : null}
    // </Label>
    <label>
      <div
        className={cn(
          BGS_BY_DIFFICULTY[challenge.difficulty],
          'flex w-full items-center justify-between gap-3 overflow-hidden rounded-lg text-xs',
          'bg-gradient-to-r from-vanta-950 from-70% to-100% dark:from-neutral-500/20',
          'p-4  py-4 text-white/90  duration-300 group-active/challenge:bg-brand-500/40 group-active/challenge:duration-75 sm:px-8 sm:py-7 ',
          {
            'group-hover/challenge:scale-105 group-hover/challenge:rounded-xl group-hover/challenge:bg-neutral-500/20':
              !isMobile,
          },
        )}
      >
        <div className="relative flex flex-row items-center gap-1 md:gap-3">
          <input
            className="peer absolute appearance-none"
            type="checkbox"
            id={challenge.id.toString()}
          />
          <div className="h-3 w-3 rounded-full border border-brand-300/70 bg-vanta-300/20 duration-75 peer-checked:border-transparent peer-checked:bg-brand-400/80 dark:border-white/50 dark:bg-white/10 peer-checked:dark:bg-brand-300/80 md:h-5 md:w-5" />
          <IconCheck className="absolute left-1 my-auto h-1 w-1 scale-0 stroke-[4] text-white duration-300 peer-checked:scale-100 dark:text-black md:h-3 md:w-3" />
          {challenge.value}
        </div>
        <div className="hidden md:block">
          {/* <DifficultyBadge difficulty={challenge.difficulty} /> */}
        </div>
      </div>
    </label>
  )
}
